import gsap, { Power3 } from 'imports/gsap'
let stickReturn = {
    currentToolTip:null,
    railSel:null,
    init: function () {
        //generate all citation
        let citationComplete = '';
        $('sup.citation').each(function( index ) {
            let citationIndex = parseFloat($(this).html()) - 1;
            let obj = citations[citationIndex]
            if (obj) {
                let html = stickReturn.citationGenerate(obj)
                $(this).append(html)
                if (obj.startNum) {
                    citationComplete += `<div class='crow lrstyle'>${stickReturn.arabicCiteNumberInserter(obj)}</div>`
                } else {
                    citationComplete += `<div class='crow'><p><span>${obj.id}. </span> ${obj.html}</p></div>`
                }
            }
        })

        $('#citations-complete').html(citationComplete);

        //enable clicks
        $('sup.citation').on('click', stickReturn.citationOpen)
        $('sup.citation .tooltip-close span').on('click', stickReturn.citationClose)

        $('#citations-toggler').on('click', stickReturn.citationsCompleteToggler)


        //INLINE IMAGES
        $('.inline-images').each(function( index ) {
            let theidIndex = -1
            if (parseFloat($(this).attr('theid')) >0) {
                theidIndex = parseFloat($(this).attr('theid')) - 1
            } else {
                theidIndex = parseFloat($(this).attr('thefigureid')) - 1
                theidIndex += 4 //figures begin on pos 4
            }
            let obj = inlineImages[theidIndex]
            if (obj) {
                let widthOverride = (obj.widthOverride !== '') ? obj.widthOverride : '50%';
                let html = `<div class="img" style="min-width:${widthOverride}">
                    <div class="theimg" theid="${obj.ID}">
                        <img src="${baseUrl}return/images/zoom/small/${inlineImagesPrefix}-${obj.ID}.jpg"/>
                        <div class="zoom-icon"></div>
                    </div>
                </div>
                <div class="img-caption"><p class="caption-p">${obj.title}</p></div>`
                $(this).html(html)
            }
        })
        $('.inline-images .theimg').on('click', stickReturn.zoomLaunchCaption);
        $('.zoom-figure').on('click', stickReturn.zoomLaunchFigure);

        stickReturn.railSel = $('.mainnew .rail-main .info')
        $(window).on("resize", stickReturn.resize);
    },
    zoomLaunchFigure:function(e) {
        e.preventDefault()
        let theid = parseFloat($(this).html())
        theid += 4 //there is an offset where the figures start after the 4th
        stickReturn.zoomLaunch(theid)
        return false
    },
    zoomLaunchCaption:function(e) {
        e.preventDefault()
        let theid = parseFloat($(this).attr('theid'))
        stickReturn.zoomLaunch(theid)
        return false
    },
    zoomLaunch:function(id) {
        let thetitle = 'Return to Palmyra'
        if (langUrl === "ar/") {
            thetitle = 'العودة إلى تدمر'
        }
        $('#zoom-header .center').html(thetitle)
        window.top.zoomOverlay.open(id,inlineImagesPrefix)
        return false
    },
    citationsCompleteToggler:function() {
        if ($('#citations-toggler').hasClass('closed')) {
            $('#citations-toggler').removeClass('closed');
            $('#citations-complete').removeClass('closed');
        } else {
            $('#citations-toggler').addClass('closed');
            $('#citations-complete').addClass('closed');
        }
    },
    citationOpen:function(e) {
        if ($('.tooltip',this).css('display') !=='block') {
            e.stopPropagation();
            stickReturn.citationClose(e);
            let sel = $('.tooltip', this)
            gsap.fromTo(sel, 0.3,
                { y: 20, opacity: 0 },
                { y: 0, opacity: 1, ease: Power3.easeOut },
            )
            stickReturn.currentToolTip = sel
            stickReturn.resize()
            sel.show()
        }
    },
    citationClose:function(e) {
        if (e) {
            e.stopPropagation()
        }
        $('sup.citation .tooltip').hide()
        stickReturn.currentToolTip = null
    },
    citationGenerate: function (obj) {
        let citationCopy = ``
        if (obj.startNum) {
            citationCopy = stickReturn.arabicCiteNumberInserter(obj)
        } else {
            citationCopy = `${obj.id}. ${obj.html}`
        }
        return `<div class='tooltip'>
            <div class='tooltip-close'>
                <span>X</span>
            </div>
            <div class='tooltip-copy'>${citationCopy}</div>
        </div>`
    },
    arabicCiteNumberInserter(obj) {
        //is arabic handling
        let tmp = `${obj.html}`
        let charToMatch = 't">' //left, right - end
        let firstPPos = tmp.indexOf(charToMatch)
        let firstPLength = charToMatch.length
        return tmp.substring(0, firstPPos+firstPLength) + `<span>${obj.id}. </span>` + tmp.substring(firstPPos+firstPLength);
    },

    resize() {
        if (stickReturn.currentToolTip) {
            let theShift = 200
            let railW = stickReturn.railSel.width()
            let parentLeft = stickReturn.currentToolTip.parent().position().left
            if (parentLeft<theShift) {
                stickReturn.currentToolTip.css('left',-parentLeft)
            } else if (parentLeft+theShift>railW) {
                let extra = (parentLeft+theShift)-railW
                stickReturn.currentToolTip.css('left',-theShift - extra)
            }

            let ttWidth = stickReturn.currentToolTip.width()
            if (railW<ttWidth) {
                stickReturn.currentToolTip.css('left',-theShift - extra)
            }
        }
    }
}
export default stickReturn
