//DEFAULTS
import "imports/core-js/stable";
import "imports/regenerator-runtime/runtime";
import stickSite from 'site/site'
import stickReturn from 'site/return'
import mobileNav from 'site/mobileNav';
import contentFillFold from 'site/contentFillFold';
$(document).ready(function() {
    stickSite.init()
    stickReturn.init()
    mobileNav.init()

    let params = {
        id: '#fill-fold',
        marginLR: 100,
        marginTB: 50,
        mobileStopScalePoint: 900, //0 for never
        ogWidth: 1610,
        ogHeight: 712,
        noCenterVertical:true,
        maxW: 1700,
        minH: 400,
        rtl: $('body').hasClass('arabic')
    }
    contentFillFold.init(params)
})