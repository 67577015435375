//import $ from 'imports/jquery'
import browser from 'imports/browser-detect'
import AOS from 'imports/aos'
import stickCommon from 'site/stick.common'
import stickReturn from 'site/return'
let stickSite = {
    browser:browser(),
    init: function () {
        //add browser name
        if (stickSite.browser && stickSite.browser.name) {
            $('body').addClass("browser_" + stickSite.browser.name)
        }

        //mobile stuff
        $('.ui-link').removeClass('ui-link')

        //disable img drag
        $('img').on('dragstart', function (event) {
            event.preventDefault()
        })
        if (stickSite.browser && stickSite.browser.mobile) {
            $('body').addClass("device-mobile")
        } else {
            $('body').addClass("device-notmobile")
        }

        //analytics
        stickCommon.scAddAnalyticsEventHandle('body')

        //aos
        setTimeout(function() {
            AOS.init({
                offset: 150,
                duration: 1000,
                once: true
            })
        },500)

        $(".scroll-to-id").click(function () {
            var theid = $(this).attr('theid');
            $('html, body').animate({
                scrollTop: $("#"+theid).offset().top
            }, 750);
            $('#sticky-nav').removeClass('open');
        });

        //scroll
        if ($('body').hasClass('page-essay')){
            $(window).on("scroll", stickSite.essayScroll)
            stickSite.essayScroll()
        }

        //final resize
        $(window).on("resize", stickSite.siteResize)
        window.dispatchEvent(new Event('resize'))
        stickSite.siteResize()
    },
    siteResize: function () {
        const docWidth = $(window).width();
        const maxW = 1200;
        let thel = (docWidth - maxW)/2;
        if (thel<0) thel = 0
        if (langUrl==='ar/') {
            thel = docWidth - thel
            $('#sticky-nav-outer').css({"left":thel,"opacity":1});
        } else {
            $('#sticky-nav-outer').css({"left":thel,"opacity":1});
        }
    },
    essayScroll :function() {
        let ret = stickCommon.isScrolledIntoView('#about-this-project','has-entered')
        if (!ret) {
            $('#sticky-nav-outer').hide();
        } else {
            $('#sticky-nav-outer').show();
        }

        if (stickReturn && stickReturn.currentToolTip) {
            let ret = stickCommon.isScrolledIntoView(stickReturn.currentToolTip,'anything-on')
            if (!ret) {
                stickReturn.citationClose(null)
            }
        }

    }
}
export default stickSite
