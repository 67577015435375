let mobileNav = {

    init:function () {
        var self = this;
        $('#mobile-nav-hamburger .hamburger').click(self.doHamburerToggle);
        $('#mobile-nav-overlay .navitem').click(self.overlayHide);
        $('#mobile-nav-overlay .has-subnav').click(self.subNavToggle);
        mobileNav.overlayHide();
        $(window).on( "resize", mobileNav.resize)
    },//init

    doHamburerToggle:function(){
        if ($(this).hasClass('is-active')) {
            mobileNav.overlayHide();
        } else {
            mobileNav.overlayShow();
        }
    },//doHamburerToggle

    subNavToggle:function(e) {
        e.preventDefault();//done allow click to go through
        if ($(this).parent().hasClass('open')) {
            mobileNav.subNavClose();
        } else {
            mobileNav.subNavOpen(this);
        }
    },
    subNavOpen:function(t) {
        mobileNav.subNavClose();
        $(t).parent().addClass('open');
    },
    subNavClose:function() {
        $('.primary').removeClass('open');
    },
    overlayShow:function() {
        $('html, body').animate({ scrollTop: 0 }, 250);
        $('header.header').addClass('mobile-open');
        $('#mobile-nav-hamburger .hamburger').addClass('is-active');
        $('#mobile-nav-overlay').removeClass('hidden');
        $('#mobile-nav-overlay-outer').removeClass('hidden');

        let tl = gsap.timeline({})
        tl.fromTo("#mobile-nav-overlay-outer", 1.4,
            {height:0},
            {height:'auto', ease:Power3.easeOut},
        );
        tl.staggerFromTo("#mobile-nav-overlay .primary", 0.5,
            {opacity:0, x:-10 },
            {opacity:1, x:0, ease:Power3.easeOut},
            0.06,//stagger
            "-=1.2"
        );
        tl.staggerFromTo("#mobile-nav-overlay .mobile-reveal", 0.5,
            {opacity:0, x:-10 },
            {opacity:1, x:0, ease:Power3.easeOut},
            0.06,//stagger,
            "-=0.8"
        );

        gsap.to(window, {duration: 0, scrollTo: {y: 0, offsetY: 0}});
    },
    overlayHide:function() {
        $('header.header').removeClass('mobile-open');
        $('#mobile-nav-hamburger .hamburger').removeClass('is-active');
        $('#mobile-nav-overlay').addClass('hidden');
        $('#mobile-nav-overlay-outer').addClass('hidden');
    },
    resize:function() {
        const docWidth = $(window).outerWidth();
        if (docWidth>=900) {
            mobileNav.overlayHide();
        }
    }

}; //obj
export default mobileNav