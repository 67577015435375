import gsap, { Power3 } from 'imports/gsap'
/* {
params: {
    id: '',
    marginLR: 0,
    marginTB: 0,
    mobileStopScalePoint: 0, //0 for never
    ogWidth: 0 //original width ex: 2000
    ogHeight: 0 //original height ex: 650
    noCenterVertical: false;
    minH:0,
    maxW:9999,
}
 */
let contentFillFold = {
    sel: '',
    selWrap: '',
    marginLR:0,
    marginTB:0,
    ogRatio:0,
    ogWidth:0,
    ogHeight:0,
    noCenterVertical:false,
    maxW:false,
    minH:false,
    rtl:false,

    init: function (params) {
        contentFillFold.sel = $(params.id)
        contentFillFold.marginLR = params.marginLR
        contentFillFold.marginTB = params.marginTB
        contentFillFold.mobileStopScalePoint = params.mobileStopScalePoint
        contentFillFold.ogWidth = params.ogWidth
        contentFillFold.ogHeight = params.ogHeight
        contentFillFold.ogRatio = params.ogWidth/params.ogHeight
        contentFillFold.noCenterVertical = params.noCenterVertical
        contentFillFold.maxW = params.maxW
        contentFillFold.minH = params.minH
        contentFillFold.rtl = params.rtl

        //set origin
        contentFillFold.sel.css({
            'transform-origin': 'top left'
        })
        let wrapid = Date.now()
        contentFillFold.sel.wrap( "<div id='fill-fold-wrap-" + wrapid + "' class='fill-fold-wrap'></div>" );
        contentFillFold.selWrap = $('#fill-fold-wrap-'+wrapid)

        $(window).on("resize", contentFillFold.resize)
        contentFillFold.resize()
    },

    resize: function() {
        const docWidth = $(window).outerWidth();
        const docHeight = $(window).height()
        let docWidthAdj = (docWidth > contentFillFold.maxW) ? contentFillFold.maxW : docWidth
        let availW = docWidthAdj - (contentFillFold.marginLR*2)
        let availH = docHeight - (contentFillFold.marginTB*2)

        //EXTRA HACKS
        availH -= 40 //to give more bottom space


        var marginLeftVar = (contentFillFold.rtl) ? 'margin-right' : 'margin-left'

        if (docWidth>contentFillFold.mobileStopScalePoint) {
            let thescale = 1
            let thew = availW
            let theh = thew / contentFillFold.ogRatio
            if (theh > availH) {
                theh = availH
                thew = theh * contentFillFold.ogRatio
            }
            if (theh<contentFillFold.minH) {
                theh = contentFillFold.minH
                thew = theh * contentFillFold.ogRatio
            }
            let thel = (docWidth - thew)/2
            let thet = (docHeight - theh)/2
            thescale = thew/contentFillFold.ogWidth
            if (contentFillFold.noCenterVertical) {
                thet = contentFillFold.marginTB
            }

            //EXTRA HACKS - because the arabic letter overhangs to the left
            if (docWidth < 1020 && !$('body').hasClass('arabic')) {
                let factor = 0.85
                let thescaleOffset = thew * thescale * factor
                thescale *= factor
                thel = (docWidth - (thew*factor))/2
            }



            //SCALE
            gsap.set(contentFillFold.sel, {
                'scale':thescale,
                'opacity':1,
                'position': 'absolute',
                'top':0,
                'left':0,
                'width':contentFillFold.ogWidth,
                'height':contentFillFold.ogHeight
            });
            let cssProps = {
                'width':thew,
                'height':theh,
                'margin-top':thet,
            }
            cssProps[marginLeftVar] = thel
            gsap.set(contentFillFold.selWrap, cssProps);

            //EXTRA
            $('#landing-featured-bg-wrapper').css({
                'margin-top':-190*thescale
            })

        } else {
            //MOBILE NO SCALE
            gsap.set(contentFillFold.sel, {
                'scale':1,
                'width':'100%',
                'height':'auto',
                'opacity':1,
                'position':'relative'
            });
            let cssProps = {
                'width':'auto',
                'height':'auto',
                'margin-top':0
            }
            cssProps[marginLeftVar] = 0
            gsap.set(contentFillFold.selWrap, cssProps);

            $('#landing-featured-bg-wrapper').css({
                'margin-top':0
            })
        }


    },

}
export default contentFillFold
